import { render, staticRenderFns } from "./appointment.html?vue&type=template&id=9f45b464&scoped=true&external"
import script from "./appointment.js?vue&type=script&lang=js&external"
export * from "./appointment.js?vue&type=script&lang=js&external"
import style0 from "./appointment.scss?vue&type=style&index=0&id=9f45b464&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f45b464",
  null
  
)

export default component.exports