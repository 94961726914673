/* eslint-disable */
import {bus} from '@/main';  
export default {
  name: 'remarks-list-pooup',
  components: {},
  props: ['remarksListPopupData'],
  data () {
    return {
      remarkListData:null,
      remarkListResults:[],
      remarkListPageTab:true,
      remarkInfoData:null
    }
  },
  computed: {

  },
  mounted () {
    this.remarkListData = this.remarksListPopupData
    this.remarkListResults = this.remarksListPopupData.data
  },
  methods: {
    closeRemarksPopup(){
      bus.$emit('remarksListPopupStateBus', false)
      document.body.classList.remove('popup_open_body')
    },
    remarkInfoFun(data){
      this.remarkInfoData = data
      this.remarkListPageTab = false
    },
    backRemarkInfoFun(){
      this.remarkInfoData = null
      this.remarkListPageTab = true
    }
  }
}